import { ProjectCard, Richtext } from '@/atoms'

const mainClassName = 'paragraph-card'

const ParagraphCard = ({ items }) => {
  const { title, description, items: _items, darkBackground = false } = items
  const hasItems = _items && _items.length > 0 ? true : false

  const renderCards = () => {
    return _items.map((item, index) => {
      return (
        <ProjectCard
          key={`paragraph-card-${index}`}
          variant={index % 2 === 0 ? 'light' : 'dark'}
          cardOrder={index}
          {...item}
        />
      )
    })
  }

  return (
    <section
      className={`${mainClassName + '_section'} ${
        darkBackground ? 'darkBg' : ''
      }`}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_paragraph'}`}>
          <h2
            className={`${
              mainClassName + '_section_container_paragraph_title'
            }`}
          >
            {title}
          </h2>
          <Richtext
            className={`${
              mainClassName + '_section_container_paragraph_description'
            }`}
            textAlign={'left'}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClassName + '_section_container_cards'}`}>
          {hasItems ? renderCards() : null}
        </div>
      </div>
    </section>
  )
}

export default ParagraphCard
