import { motion } from 'framer-motion'
import { useState } from 'react'
import { countCharsInHtmlString } from 'utils/functions'
import { Richtext, ShowMoreButton } from '.'

const defaultClassName = 'text-collapser'

const TextCollapser = ({
  className = '',
  children,
  maxCharsLength = 200,
  charOffset = 25,
  hasCta = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const textClassName = `${isOpen ? 'open' : ''} ${className}`
  const isLongText =
    countCharsInHtmlString(children) > maxCharsLength + charOffset

  const getTextToRender = () => {
    return !isOpen && isLongText
      ? children.slice(0, maxCharsLength).concat('...')
      : children
  }

  const onButtonPress = () => {
    setIsOpen(!isOpen)
  }

  const variants = {
    open: {
      opacity: [0, 1],
      y: [-20, 0],
      initial: {
        opacity: 1,
        y: 20,
      },
      transition: {
        duration: 0.35,
      },
    },
    closed: {
      opacity: [0, 1],
      y: [20, 0],
      initial: {
        opacity: 1,
      },
      transition: {
        duration: 0.35,
      },
    },
  }

  return (
    <div className={`${defaultClassName}-container`}>
      <motion.div
        animate={!isOpen ? 'closed' : 'open'}
        variants={variants}
        className={`${defaultClassName}-container-content`}
      >
        <Richtext
          className={textClassName}
          textAlign={'left'}
        >
          {getTextToRender()}
        </Richtext>
      </motion.div>

      {isLongText && hasCta ? (
        <ShowMoreButton
          onClick={onButtonPress}
          isOpen={isOpen}
        />
      ) : null}
    </div>
  )
}

export default TextCollapser
