import { Richtext } from '@/atoms'
import Image from 'next/image'

const mainClassName = 'paragraph-with-image'
const ParagraphWithImage = ({ items }) => {
  const {
    text: title,
    description,
    image,
    objectFit: imageObjectFit = 'cover',
  } = items

  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_text'}`}>
          <h2 className={`${mainClassName + '_section_container_text_title'}`}>
            {title}
          </h2>
          <Richtext
            textAlign={'left'}
            className={`${
              mainClassName + '_section_container_text_description'
            }`}
            isDefault={false}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClassName + '_section_container_image'}`}>
          <Image
            src={image.url}
            alt={image.alt}
            fill
            title={image.title}
            style={{ objectFit: imageObjectFit }}
          />
        </div>
      </div>
    </section>
  )
}

export default ParagraphWithImage
