import { Button, DateSpan, Richtext, Tag } from '@/atoms'
import { ArrowRight } from '@/molecules/Svgs'
import Image from 'next/image'

const mainClass = 'evidence-card'
const EvidenceCard = ({ title, image, tag, date, description, cta }) => {
  return (
    <section className={`${mainClass}_section`}>
      <div className={`${mainClass}_section_container`}>
        <div className={`${mainClass}_section_container_image`}>
          <Image
            src={image.src ?? image}
            alt={image.alt ?? 'Immagine carosello'}
            fill
          />
        </div>
        <div className={`${mainClass}_section_container_paragraph`}>
          <div className={`${mainClass}_section_container_paragraph_top`}>
            <Tag label={tag} />
            <DateSpan date={date} />
          </div>
          <div className={`${mainClass}_section_container_paragraph_text`}>
            <h3
              className={`${mainClass}_section_container_paragraph_text_title`}
            >
              {title}
            </h3>
            <Richtext
              textAlign={'left'}
              className={`${mainClass}_section_container_paragraph_text_description`}
            >
              {description}
            </Richtext>
          </div>
          <Button
            href={cta?.url}
            variant={'link'}
            internalUrl={cta?.internal_url}
            isInternalPage={cta?.internal_page}
            openInNewTab={cta?.open_link_in_new_tab}
            className={`${mainClass}_section_container_paragraph_button`}
          >
            {cta.label.toUpperCase()}
            <ArrowRight />
          </Button>
        </div>
      </div>
    </section>
  )
}

export default EvidenceCard
