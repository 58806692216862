import {
  AccordionBody,
  AccordionHeader,
  Accordion as DesignReactKitAccordion,
} from 'design-react-kit'
import { useState } from 'react'
import Richtext from './Richtext'

const Accordion = ({
  title,
  children,
  activeIndex = null,
  isMutuallyExclusive = false,
  mutuallyExclusiveActiveIndex = null,
  handleMutuallyExclusiveToggle = () => {},
  isUnwrapped = false,
}) => {
  const mainClassName = 'accordion'
  const [isOpen, setIsOpen] = useState(false)

  const toggle = isMutuallyExclusive
    ? () => handleMutuallyExclusiveToggle(activeIndex)
    : () => setIsOpen(!isOpen)

  const getIsOpen = () =>
    isMutuallyExclusive ? mutuallyExclusiveActiveIndex === activeIndex : isOpen

  const renderAccordionContent = () => (
    <>
      <AccordionHeader
        active={getIsOpen()}
        onToggle={toggle}
        className={`${mainClassName + '_header'}`}
      >
        {title}
        {/* <ChevronDown className={`${mainClassName + '_header_chevron'}`} /> */}
      </AccordionHeader>
      <AccordionBody
        className={`${mainClassName + '_body'}`}
        active={getIsOpen()}
      >
        <Richtext
          className={`${mainClassName + '_body_text'}`}
          textAlign={'left'}
        >
          {children}
        </Richtext>
      </AccordionBody>
    </>
  )

  return isUnwrapped ? (
    renderAccordionContent()
  ) : (
    <DesignReactKitAccordion background='active'>
      {renderAccordionContent()}
    </DesignReactKitAccordion>
  )
}

const AccordionList = ({ items, isMutuallyExclusive = false }) => {
  const mainClassName = 'accordion-list'
  const [mutuallyExclusiveActiveIndex, setMutuallyExclusiveActiveIndex] =
    useState(null)

  const handleMutuallyExclusiveToggle = (index) => {
    setMutuallyExclusiveActiveIndex(
      mutuallyExclusiveActiveIndex === index ? null : index,
    )
  }

  return (
    <DesignReactKitAccordion background='active'>
      {items.length > 0
        ? items.map((item, index) => (
            <Accordion
              activeIndex={`accordion-${index}`}
              key={`accordion-${index}`}
              title={item.title}
              handleMutuallyExclusiveToggle={handleMutuallyExclusiveToggle}
              mutuallyExclusiveActiveIndex={mutuallyExclusiveActiveIndex}
              isMutuallyExclusive={isMutuallyExclusive}
              isUnwrapped
            >
              {item.text}
            </Accordion>
          ))
        : null}
    </DesignReactKitAccordion>
  )
}

export { Accordion, AccordionList }
