import { EvidenceCard, Slider } from '@/atoms'

const mainClassName = 'cards-slider'
const sliderBreakpoints = {
  0: {
    slidesPerView: 1.15,
    spaceBetween: 24,
  },
  415: {
    slidesPerView: 1.5,
  },
  768: {
    slidesPerView: 1,
    spaceBetween: 32,
  },
}

const CardsSlider = ({ items }) => {
  const { title, items: _items, backgroudColor = 'dark' } = items
  const hasItems = _items && _items.length > 0 ? true : false
  const isDark = backgroudColor === 'dark'

  const sliderItems =
    hasItems &&
    _items?.map((item, index) => {
      return (
        <EvidenceCard
          key={`evidence-card-${index}`}
          {...item}
        />
      )
    })
  return (
    <section
      className={`${mainClassName}_section  ${isDark ? 'dark' : 'light'}`}
    >
      <div className={`${mainClassName}_section_container`}>
        <div className={`${mainClassName}_section_container_paragraph`}>
          <h2 className={`${mainClassName}_section_container_paragraph_title`}>
            {title}
          </h2>
        </div>
        {hasItems ? (
          <div className={`${mainClassName}_section_container_slider`}>
            <Slider
              breakpoints={sliderBreakpoints}
              variant={isDark ? 'dark' : 'light'}
              className={`${mainClassName}`}
            >
              {sliderItems}
            </Slider>
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default CardsSlider
