const DLHero = ({ className }) => (
  <svg
    className={className}
    width='517'
    height='360'
    viewBox='0 0 517 360'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M277 0H337V60H277V0Z'
      fill='#53A6A3'
    />
    <path
      d='M277 120H337V180H277V120Z'
      fill='#53A6A3'
    />
    <path
      d='M277 180H337V240H277V180Z'
      fill='#0066CC'
    />
    <path
      d='M277 240H337V300H277V240Z'
      fill='#53A6A3'
    />
    <path
      d='M337 240H397V300H337V240Z'
      fill='#FCF7E6'
    />
    <path
      d='M457 240H517V300H457V240Z'
      fill='#FCF7E6'
    />
    <path
      d='M0 1H125.125C155.018 1 181.445 7.19271 204.406 19.5781C226.915 31.5268 245.491 49.7012 257.906 71.9213C270.623 94.4071 276.988 120.6 277 150.5C277.012 180.4 270.647 206.605 257.906 229.115C245.485 251.326 226.91 269.493 204.406 281.44C181.445 293.813 155.018 300 125.125 300H0V1ZM121.982 243.208C149.512 243.208 171.474 234.876 187.87 218.213C204.266 201.549 212.464 178.978 212.464 150.5C212.464 122.022 204.266 99.4512 187.87 82.7874C171.474 66.1237 149.512 57.7979 121.982 57.81H63.7502V243.208H121.982Z'
      fill='#53A6A3'
    />
    <path
      d='M0 128H64V192H0V128Z'
      fill='#FCF7E6'
    />
    <path
      d='M64 128H128V192H64V128Z'
      fill='#0066CC'
    />
    <rect
      x='217'
      y='300'
      width='60'
      height='60'
      fill='#26394D'
    />
  </svg>
)

export default DLHero
