import { ENDPOINTS } from './config'

export const getApiUrl = (lang = '') => {
  return `${process.env.API_URL}${lang}`
}

/* Use to format internal url using slug */
export const formatUrl = (url) => {
  let domain = process.env.API_URL

  if (process.env.NODE_ENV === 'development') {
    domain = 'http://localhost:3000/'
  }

  return `${domain}${url}`
}

const getPaginationUrls = (url, req) => {
  const pagesRemained = req.headers.get('x-wp-totalpages') ?? 0
  if (pagesRemained === 0) return []

  const numberPages = Array.from(
    { length: pagesRemained },
    (_, i) => i + 1,
  ).splice(1)
  const paginationUrls = numberPages.map((page) => {
    const pageUrl = url.concat(`&page=${page}`)
    return pageUrl
  })
  return paginationUrls
}

export const fetchData = async (url, opt = {}) => {
  let response
  const isPaginationFetch = url.includes('per_page=')
  let paginationResponses = []

  try {
    const req = await fetch(url, opt)
    console.info('\t[ 🌐 GET:', url, '- STATUS: ', req.status, ' ]')
    if (req.status === 404) return null
    if (isPaginationFetch && req?.headers) {
      const paginationUrls = getPaginationUrls(url, req)
      if (paginationUrls.length > 0) {
        paginationResponses = await Promise.all(
          paginationUrls.map(
            async (url) => await fetch(url, opt).then((res) => res.json()),
          ),
        )
      }
      response = [...(await req.json()), ...(paginationResponses.flat() ?? [])]
    }
    return response ?? (await req.json())
  } catch (err) {
    console.error(err)
    return null
  }

  return response
}

export const getPageData = async ({ entity = 'pages', slug = '' }) => {
  const baseUrl = getApiUrl()
  let url = `${baseUrl}${ENDPOINTS[entity]}${
    slug === false ? '' : `?slug=${slug}`
  }`
  const res = await fetchData(url)
  if (Array.isArray(res) && res.length === 1) {
    return res.at(0)
  }
  return res
}

export const getPageById = async (
  { entity = 'pages', id = '', isDraft = false },
  fetchOptions,
) => {
  const baseUrl = getApiUrl()
  const url = `${baseUrl}${ENDPOINTS[entity]}/?include=${id}${
    isDraft ? `&status=draft` : ''
  }`
  const [data] = await fetchData(url, fetchOptions)
  return data
}

export const getHeaderData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.header
  const apiUrl = `${baseUrl}${endpoint}`
  const data = await fetchData(apiUrl)
  return data
}

export const getFooterData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.footer
  const apiUrl = `${baseUrl}${endpoint}`
  const data = await fetchData(apiUrl)
  return data
}

export const getEntity = async ({ entity }) => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS[entity]
  const perPage = `?per_page=100`
  const apiUrl = `${baseUrl}${endpoint}${perPage}`
  const data = await fetchData(apiUrl)
  if (data && data.length > 0) {
    data[0].entity = entity
  }
  return data
}
