const ReactagleGradient = ({ className }) => (
  <svg
    className={className}
    width='145'
    height='67'
    viewBox='0 0 145 67'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      width='297'
      height='67'
      fill='url(#paint0_linear_1271_78620)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1271_78620'
        x1='297'
        y1='33.5'
        x2='2.25867e-06'
        y2='33.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9DE0DE' />
        <stop
          offset='0.78125'
          stopColor='#9DE0DE'
          stopOpacity='0'
        />
      </linearGradient>
    </defs>
  </svg>
)

export default ReactagleGradient
