import { ChevronLeft, ChevronRight } from '@/molecules/Svgs'
import { useMediaQuery } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/scrollbar'

const VARIANTS = {
  light: 'light',
  dark: 'dark',
}

const Slider = ({
  children = null,
  breakpoints = {},
  slidesPerView = 'auto',
  variant = 'light',
  className = '',
  ...props
}) => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [mounted, setMounted] = useState(false)
  const [sliderStatus, setSliderStatus] = useState({
    isBeginning: true,
    isEnd: false,
    activeIndex: 0,
  })
  const isDesktop = useMediaQuery('md')
  const isSmallResolution = useMediaQuery('small-screen-resolution')
  const isFirstSlide = sliderStatus.isBeginning
  const isLastSlide = sliderStatus.isEnd
  const isOnlyOneSlide = children?.length === 1
  const hideNavigation =
    sliderStatus.activeIndex === children?.length - 1 ||
    !isOnlyOneSlide ||
    isDesktop

  const getDisabledClass = (isDisabled) => (isDisabled ? 'disabled' : '')

  const handleBeforeInit = (swiper) => {
    swiper.params.navigation.prevEl = navigationPrevRef.current
    swiper.params.navigation.nextEl = navigationNextRef.current
    setSliderStatus({
      isBeginning: swiper.isBeginning,
      isEnd: swiper.isEnd,
      activeIndex: swiper.activeIndex,
    })
  }

  const handleSlideChange = (swiper) => {
    setSliderStatus({
      isBeginning: swiper.isBeginning,
      isEnd: swiper.isEnd,
    })
  }

  const renderChildrenSlides = () => {
    if (!children) return null
    if (isOnlyOneSlide) {
      return children?.map((child, index) => {
        return (
          <div
            key={`slide-${index}`}
            style={{
              width: '100%',
              maxWidth: '67rem',
            }}
          >
            {child}
          </div>
        )
      })
    }
    return children?.map((child, index) => {
      return (
        <SwiperSlide
          className={className}
          key={`slide-${index}`}
          style={
            isOnlyOneSlide
              ? {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginInline: 'auto !important',
                }
              : {}
          }
        >
          {child}
        </SwiperSlide>
      )
    })
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null

  return (
    <section className={`swiper-custom-wrapper ${VARIANTS[variant]} `}>
      {isDesktop && !isOnlyOneSlide ? (
        <div
          ref={navigationPrevRef}
          className={`
          swiper-custom-button-prev ${VARIANTS[variant]} ${getDisabledClass(
            isFirstSlide || isOnlyOneSlide,
          )}
          `}
        >
          <ChevronLeft />
        </div>
      ) : null}
      {isDesktop && !isOnlyOneSlide ? (
        <div
          ref={navigationNextRef}
          className={`
          swiper-custom-button-next ${VARIANTS[variant]} ${getDisabledClass(
            isLastSlide || isOnlyOneSlide,
          )}`}
        >
          <ChevronRight />
        </div>
      ) : null}
      {isOnlyOneSlide ? (
        <div className={`swiper-custom-wrapper-only-child`}>
          {renderChildrenSlides()}
        </div>
      ) : (
        <Swiper
          modules={[A11y, Navigation, Pagination, Scrollbar]}
          spaceBetween={isOnlyOneSlide ? 0 : 24}
          grabCursor
          centeredSlides={!isDesktop}
          watchOverflow={true}
          slidesPerView={slidesPerView}
          className={`swiper-custom-${VARIANTS[variant]} ${className}`}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={handleBeforeInit}
          breakpoints={isSmallResolution ? {} : breakpoints}
          onSlideChange={handleSlideChange}
          {...props}
        >
          {renderChildrenSlides()}
        </Swiper>
      )}
    </section>
  )
}

export default Slider
