import { NavScroll } from '@/molecules'

const mainClassName = 'big-paragraph'
const BigParagraph = ({ items, title, intro, ...props }) => {
  return (
    <section className={`${mainClassName + '_section'}`}>
      <NavScroll
        navScrollItems={items}
        header={title}
        intro={intro}
      />
    </section>
  )
}

export default BigParagraph
