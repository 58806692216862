import { Breadcrumbs, Richtext } from '@/atoms'
import { Hero, HeroBody } from 'design-react-kit'
import Image from 'next/image'
import { getReadableDate } from 'utils/functions'

const mainClassName = 'full-overlay-hero'
const publishedAtLabel = 'Pubblicato il: \xa0'

const FullOverlayHero = ({ items, isHero }) => {
  const { title, description, image, published_at: publishedAt } = items

  return (
    <section className={`${mainClassName + '_section'}`}>
      <Hero overlay='dark'>
        <div className='img-responsive-wrapper'>
          <div className='img-responsive'>
            <div className='img-wrapper'>
              <Image
                src={image.url ?? image}
                fill
                alt={image.alt ?? 'Immagine sfondo hero'}
                className={`${mainClassName}_section_bg-img`}
              />
            </div>
          </div>
        </div>
        <HeroBody className={`${mainClassName + '_section_text-wrapper'}`}>
          {isHero ? (
            <Breadcrumbs
              className={`${
                mainClassName + '_section_text-wrapper_breadcrumbs'
              }`}
            />
          ) : null}
          <h1 className={`${mainClassName + '_section_text-wrapper_title'}`}>
            {title}
          </h1>
          <Richtext
            text={description}
            textAlign={'left'}
            className={`${mainClassName + '_section_text-wrapper_description'}`}
          >
            {description}
          </Richtext>
          <div
            className={`${
              mainClassName + '_section_text-wrapper_published-at'
            }`}
          >
            <p>
              {publishedAtLabel}
              <b style={{ fontSize: '1.125rem' }}>
                {getReadableDate(publishedAt)}
              </b>
            </p>
          </div>
        </HeroBody>
      </Hero>
    </section>
  )
}

export default FullOverlayHero
