import { useEffect, useState } from 'react'

const BREAKPOINTS = {
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1400px)',
  'small-screen-resolution':
    '(min-width: 800px) and (min-height: 500px) and (max-width: 1280px) and (max-height: 800px)',
}

const getBreakpointFromDefault = (inputMediaQuery) => {
  const breakpointNames = Object.keys(BREAKPOINTS)

  if (breakpointNames.includes(inputMediaQuery)) {
    return BREAKPOINTS[inputMediaQuery]
  }
  return inputMediaQuery
}

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const _query = getBreakpointFromDefault(query)
    const media = window.matchMedia(_query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [matches, query])

  return matches
}

export default useMediaQuery
