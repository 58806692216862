import YouTube from '@u-wave/react-youtube'
import { useEffect, useState } from 'react'

const mainClassName = 'full-video'

const FullVideo = ({ items: { url: videoSrc } }) => {
  const [mounted, setMounted] = useState(false)
  const videoID = getIDfromURL(videoSrc)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null
  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_section_container'}`}>
        <YouTube
          video={videoID}
          className={`${mainClassName + '_section_container_video'}`}
          width='100%'
          height='100%'
          annotations={false}
          modestBranding={true}
          showRelatedVideos={false}
          volume={0.5}
        />
      </div>
    </section>
  )
}

const getIDfromURL = (url) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const isValidUrl = match && match[2].length === 11
  const result = isValidUrl ? match[2] : ''
  !isValidUrl && console.error('The supplied URL is not a valid youtube URL')
  return result
}

export default FullVideo
