import Button from './Button'

const defaultClassName = 'text-collapser'

const ShowMoreButton = ({ isOpen = false, onClick }) => {
  return (
    <Button
      variant={'link'}
      onClick={onClick}
      className={`${defaultClassName}-container-button`}
      id={'show-more-button'}
    >
      {`Mostra di ${isOpen ? 'meno' : 'più'}`}
    </Button>
  )
}

export default ShowMoreButton
