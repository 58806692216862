import {
  Header,
  HeaderBrand,
  HeaderContent,
  HeaderRightZone,
  HeaderSearch,
  HeaderSocialsZone,
  Icon,
} from 'design-react-kit'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { withNextLink } from 'utils/functions'

const centerHeaderCopy = {
  followUs: '',
  twitter: 'Twitter',
  linkedin: 'Linkedin',
  search: '',
  searchAriaLabel: 'Prosegui verso la pagina di ricerca',
}

const CenterHeader = ({
  title = 'Ministero della Cultura',
  subtitle = 'Istituto centrale per la digitalizzazione del patrimonio culturale',
  logo,
  socialItems = [],
  searchItem = {},
  onSearchClick = () => {},
}) => {
  const router = useRouter()
  const socials = socialItems.map((item) => ({
    href: item.url,
    'aria-label': item.social,
  }))

  const renderSocialIcons = () => {
    if (!socialItems || !socialItems.length) return null
    return socialItems.map((item, index) => {
      const socialIconName =
        item.social.toLowerCase() === 'x'
          ? 'it-twitter'
          : `it-${item.social.toLowerCase()}`

      return (
        <li key={`social-${index}`}>
          <Link
            target='_blank'
            href={item.url}
            aria-label={item.social}
          >
            <Icon
              icon={socialIconName}
              aria-label={item.social}
              title={item.social}
            />
          </Link>
        </li>
      )
    })
  }

  return (
    <Header
      theme=''
      type='center'
    >
      <HeaderContent>
        <HeaderBrand iconName={logo}>
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
        </HeaderBrand>
        {/* <div className={'it-brand-wrapper'}>
          <Link href='/'>
            <Image
              className={'icon'}
              src={logo}
              alt='logo'
              width={500}
              height={200}
            />
          </Link>
        </div> */}
        <HeaderRightZone>
          <HeaderSocialsZone
            className={'center-header_socials-zone'}
            label={centerHeaderCopy.followUs}
          >
            <ul>{renderSocialIcons()}</ul>
          </HeaderSocialsZone>

          {withNextLink(
            <HeaderSearch
              href={searchItem?.url}
              aria-label={centerHeaderCopy.searchAriaLabel}
              iconName='it-search'
              label={centerHeaderCopy.search}
              style={{
                cursor: 'pointer',
              }}
            />,
            searchItem?.url,
          )}
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  )
}

export default CenterHeader
