import { Button, Richtext } from '@/atoms'
import { useMediaQuery } from 'hooks'
import Image from 'next/image'
import { Fragment } from 'react'

const mainClassName = 'dark-paragraph-image'

const IMAGE_ALIGN_TYPE = {
  LEFT: 'left',
  RIGHT: 'right',
}

const DarkParagraphWithImage = ({ items }) => {
  const {
    text: title,
    description,
    cta,
    image,
    imagealign: imageAlign = IMAGE_ALIGN_TYPE.RIGHT,
    useParallax = false,
    objectFit: imageObjectFit = 'cover',
  } = items

  const isDesktop = useMediaQuery('md')
  const isLeftAlign = imageAlign === IMAGE_ALIGN_TYPE.LEFT && isDesktop

  const getContentByImageAlign = () => {
    if (isLeftAlign) {
      return (
        <Fragment>
          <ImageContent
            image={image}
            useParallax={useParallax}
            imageObjectFit={imageObjectFit}
          />
          <TextContent
            title={title}
            description={description}
            cta={cta}
            isLeftAlign={isLeftAlign}
          />
        </Fragment>
      )
    }
    return (
      <Fragment>
        <TextContent
          title={title}
          description={description}
          cta={cta}
          isLeftAlign={isLeftAlign}
        />
        <ImageContent
          image={image}
          useParallax={useParallax}
          imageObjectFit={imageObjectFit}
        />
      </Fragment>
    )
  }

  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_section_container'}`}>
        {getContentByImageAlign()}
      </div>
    </section>
  )
}

const TextContent = ({ title, description, cta, isLeftAlign = false }) => {
  const hasCta = cta && cta.label
  const textContainerClassName = isLeftAlign ? 'left' : 'right'

  return (
    <div
      className={`${
        mainClassName + '_section_container_text'
      } ${textContainerClassName}`}
    >
      <h2 className={`${mainClassName + '_section_container_text_title'}`}>
        {title}
      </h2>
      <Richtext
        textAlign={'left'}
        isDefault={false}
        className={`${mainClassName + '_section_container_text_description'}`}
      >
        {description}
      </Richtext>
      {hasCta && (
        <Button
          className={`${mainClassName + '_section_container_text_cta'}`}
          fullWidth={false}
          href={cta?.url}
          internalUrl={cta?.internal_url}
          isInternalPage={cta?.internal_page}
          openInNewTab={cta?.open_link_in_new_tab}
          variant={'outline'}
        >
          {cta.label}
        </Button>
      )}
    </div>
  )
}

const ImageContent = ({
  image,
  useParallax = true,
  imageObjectFit = 'cover',
}) => {
  return (
    <div className={`${mainClassName + '_section_container_image'}`}>
      <div className={`${mainClassName + '_section_container_image_wrapper'}`}>
        {useParallax ? (
          <div
            className={`${
              mainClassName + '_section_container_image_wrapper_image'
            } parallax`}
            style={{
              backgroundImage: `url(${image.url})`,
              height: '100dvh',
            }}
          />
        ) : (
          <Image
            className={`${
              mainClassName + '_section_container_image_wrapper_image'
            }`}
            src={image.url}
            alt={image.alt}
            fill
            title={image.title}
            style={{ objectFit: imageObjectFit }}
          />
        )}
      </div>
    </div>
  )
}

export default DarkParagraphWithImage
