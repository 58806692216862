const SixRectangles = ({ className }) => (
  <svg
    className={className}
    width='116'
    height='148'
    viewBox='0 0 116 148'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 0H24.3048V24.2985H0V0Z'
      fill='#FCF7E6'
    />
    <path
      d='M0 49.7015H24.3048V74H0V49.7015Z'
      fill='#0066CC'
    />
    <path
      d='M48.6095 49.7015H72.9143V74H48.6095V49.7015Z'
      fill='#FCF7E6'
    />
    <path
      d='M24.3048 74H48.6095V98.2985H24.3048V74Z'
      fill='#334C66'
    />
    <path
      d='M67.3905 123.701H91.6952V148H67.3905V123.701Z'
      fill='#4F4D46'
    />
  </svg>
)

export default SixRectangles
