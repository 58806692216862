import { Card, CardBody } from 'design-react-kit'
import Richtext from '../Richtext'

const mainClassName = 'blockquote-card'

const BlockquoteCard = ({ quote, author, innerKey }) => {
  return (
    <Card
      key={innerKey}
      className={`${mainClassName}_container shadow`}
    >
      <CardBody className={`${mainClassName}_container_body`}>
        <Richtext
          className={`${mainClassName}_container_body_quote`}
          textAlign={'justify'}
          fullWidth={false}
        >
          {quote}
        </Richtext>
        <div className={`${mainClassName}_container_body_author`}>{author}</div>
      </CardBody>
    </Card>
  )
}

export default BlockquoteCard
