import { SmallParagraph } from '..'

const wrapperStyle = {
  width: '100%',
  border: '3px solid red',
  position: 'fixed',
  top: 200,
  right: 0,
  maxWidth: '300px',
  zIndex: 1000,
}

const smallParagraphProps = {
  items: {
    title: 'Preview Mode Attiva',
    text: 'In modalità preview è possibile visualizzare le pagine in bozza (o draft) per apportate modifiche al sito prima di pubblicarle. <br/> Per disattivare la modalità preview clicca sul pulsante sottostante. <br/> N.B. Una volta disattivata la modalità preview, per riattivarla è necessario ri-aprire una pagina in modalità anteprima da Wordpress.',
    alignContent: 'center',
    hasCta: true,
    cta: {
      label: 'Disattiva Preview Mode',
      url: '/api/disable-preview',
    },
  },
  containerStyle: {
    padding: '1rem',
    margin: 'auto',
  },
  titleStyle: {
    fontSize: '1.25rem',
    lineHeight: '1em',
  },
  textStyle: {
    fontSize: '1rem',
    lineHeight: '1.2em',
  },
}

const PreviewBadge = () => {
  return (
    <div style={wrapperStyle}>
      <SmallParagraph {...smallParagraphProps} />
    </div>
  )
}

export default PreviewBadge
