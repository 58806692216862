import { Card } from 'design-react-kit'
import { useMediaQuery } from 'hooks'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { TextCollapser } from '..'
import Button from '../Button'
import DateSpan from '../DateSpan'
import Tag from '../Tag'

const mainClassName = 'dynamic-card'

const DynamicCard = ({
  category,
  date,
  image,
  title,
  description,
  cta,
  innerKey,
  isMobileOnly = false,
  showDate = true,
  showImage = true,
}) => {
  const isDesktop = useMediaQuery('md')
  const router = useRouter()
  const isMobile = isMobileOnly || !isDesktop
  const hasImage = image && image.length > 0 ? true : false
  const hasDescription = description && description.length > 0 ? true : false
  const isMinimal = (!hasImage || showImage === false) && !hasDescription

  const hadleClick = (e) => {
    e.preventDefault()
    router.push(cta?.url)
  }

  return (
    <Card
      key={innerKey}
      id='card-container'
      className={`${mainClassName}_container
      ${isMinimal ? 'minimal' : ''}
       shadow-sm`}
    >
      {image && showImage ? (
        <div className={`${mainClassName}_container_image`}>
          <Image
            fill
            src={image}
            alt='card-cover'
          />
        </div>
      ) : null}

      <div className={`${mainClassName}_container_body`}>
        {!isMobile ? (
          <div className={`${mainClassName}_container_body_tag`}>
            <Tag
              label={category}
              className={`${mainClassName}_container_body_tag_content`}
            />

            {date && showDate ? <DateSpan date={date} /> : null}
          </div>
        ) : null}

        <div className={`${mainClassName}_container_body_paragraph`}>
          <Link href={cta?.url ?? '#'}>
            <h3 className={`${mainClassName}_container_body_paragraph_title`}>
              {title}
            </h3>
          </Link>

          {!isMobile ? (
            <TextCollapser
              className={`${mainClassName}_container_body_paragraph_description`}
              maxCharsLength={150}
              hasCta={false}
            >
              {description}
            </TextCollapser>
          ) : null}
        </div>

        <Button
          href={cta?.url}
          variant='secondary'
          internalUrl={cta?.internal_url}
          isInternalPage={cta?.internal_page}
          openInNewTab={cta?.open_link_in_new_tab}
          className={`${mainClassName}_container_body_button`}
          onClick={hadleClick}
        >
          {cta?.label}
        </Button>
      </div>
    </Card>
  )
}

export default DynamicCard
