import { Richtext } from '@/atoms'
import {
  LinkList,
  NavItem,
  NavLink,
  Navbar,
  useNavScroll,
} from 'design-react-kit'
import { useMediaQuery } from 'hooks'
import { useCallback, useRef, useState } from 'react'
import { withNextLink } from 'utils/functions'
import { ChevronDown } from '../Svgs'

const mainClassName = 'side-nav-scroll'

const NavScroll = ({ navScrollItems, header, intro }) => {
  const [isOpen, toggleNaveScroll] = useState(false)
  const containerRef = useRef(null)
  const { register } = useNavScroll({
    root: containerRef?.current,
  })
  const [selectedLinkId, setSelectedLinkId] = useState(null)
  const hasNavScrollItems = navScrollItems && navScrollItems.length > 0

  const onNodeClick = (id) => (e) => {
    setSelectedLinkId(id)
  }

  const isDesktop = useMediaQuery('lg')

  const generateSubTree = (item, alreadyHasParent) => {
    if (alreadyHasParent) {
      return <>{item.children?.map((_item) => generateSubTree(_item))}</>
    }
    const childrenHasParent = false
    const nestingLevel = +item.title_tipograpy.replace('H', '') - 2
    return (
      <NodeLink
        key={`${item.order}-key`}
        id={item.order}
        title={item.title}
        nestingLevel={nestingLevel}
        onNodeClick={onNodeClick}
        selectedLinkId={selectedLinkId}
      >
        {item.children?.map((_item) => {
          return generateSubTree(_item, childrenHasParent)
        })}
      </NodeLink>
    )
  }

  const generateTree = useCallback(() => {
    return (
      <LinkListWrapper>
        {navScrollItems.map((item, index) => {
          const alreadyHasParent = true
          return (
            <FirstLevelLink
              id={item.order}
              title={item.title}
              key={item.order}
              onRootClick={onNodeClick}
              selectedLinkId={selectedLinkId}
            >
              {generateSubTree(item, alreadyHasParent)}
            </FirstLevelLink>
          )
        })}
      </LinkListWrapper>
    )
  }, [navScrollItems, selectedLinkId])

  const generateTextContent = useCallback(() => {
    return navScrollItems.map((item, index) => {
      return (
        <SubParagraph
          key={index}
          tag={item.title_tipograpy}
          title={item.title}
          text={item.text}
          register={register}
          id={item.order}
          parent={null}
          _children={item.children}
        />
      )
    })
  }, [navScrollItems, selectedLinkId])

  return (
    <section className={`${mainClassName + '_section'}`}>
      {!isDesktop ? (
        <h2 className={`${mainClassName + '_section_container_title'}`}>
          {header}
        </h2>
      ) : null}
      <div className={`${mainClassName + '_section_container'}`}>
        {hasNavScrollItems ? (
          <Navbar
            expand={'lg'}
            className={`${
              mainClassName + '_section_container_navbar'
            } it-navscroll-wrapper inline-menu affix-top  `}
          >
            <div className={`menu-wrapper`}>
              <div className='link-list-wrapper'>
                {isDesktop && header ? (
                  <h2
                    className={`${
                      mainClassName + '_section_container_navbar_list-header'
                    }`}
                  >
                    {header}
                  </h2>
                ) : null}
                {generateTree()}
              </div>
            </div>
          </Navbar>
        ) : null}
        <div
          className={`${mainClassName + '_section_container_content'}`}
          ref={containerRef}
        >
          {isDesktop ? (
            <h2
              className={`${
                mainClassName + '_section_container_content_title'
              }`}
            >
              {header}
            </h2>
          ) : null}
          <Richtext
            textAlign={'left'}
            fullWidth={false}
            className={`${mainClassName + '_section_container_content_intro'}`}
          >
            {intro}
          </Richtext>
          {generateTextContent()}
        </div>
      </div>
    </section>
  )
}

const FirstLevelLink = ({
  children = null,
  id,
  title,
  selectedLinkId,
  onRootClick = () => {},
}) => {
  const href = `#${id}`
  const [isOpen, setIsOpen] = useState(false)

  const onAccordionClick = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  return (
    <li>
      <LinkList
        className={'CUSTOM_LI_FirstLevel_1'}
        noWrapper
        key={id}
      >
        <NavItem>
          <NavLink
            href={href}
            className={`${
              id === selectedLinkId ? 'selected' : ''
            } node-root right-icon
           ${isOpen ? '' : 'collapsed'}
          `}
            data-toggle='collapse'
            aria-expanded={isOpen}
            onClick={onRootClick(id)}
          >
            <span className={'node-root_span'}>{title}</span>
            {children !== null && children.props.children.length > 0 ? (
              <span onClick={onAccordionClick}>
                <ChevronDown
                  className={'chevron'}
                  isOpen={isOpen}
                />
              </span>
            ) : null}
          </NavLink>
          {children ? (
            <LinkList
              className={isOpen ? 'collapse show' : 'collapse'}
              noWrapper
            >
              {children}
            </LinkList>
          ) : null}
        </NavItem>
      </LinkList>
    </li>
  )
}

const NodeLink = ({
  children = null,
  id,
  title,
  nodeKey,
  nestingLevel,
  onNodeClick,
  getActiveClass,
  getNodeClass,
  selectedLinkId,
}) => {
  const href = `#${id}`
  return (
    <NavLink
      key={nodeKey}
      tag='li'
      className={` node-leave_li`}
    >
      {
        (withNextLink(
          <NavLink>
            <span className='node-leave_span'>{title}</span>
          </NavLink>,
        ),
        href,
        {},
        {
          href: href,
          className: `${selectedLinkId === id ? 'selected' : ''} node-leave_a`,
          'data-nesting-level': nestingLevel,
          onClick: onNodeClick(id),
        })
      }
      {children ? <LinkList noWrapper>{children}</LinkList> : null}
    </NavLink>
  )
}

const LinkListWrapper = ({ children }) => {
  return <LinkList noWrapper>{children}</LinkList>
}

const SubParagraph = ({
  tag,
  title,
  text,
  register,
  id: _id,
  parent,
  _children,
  isRecursion = false,
}) => {
  const titleClassName = `${
    mainClassName + '_section_container_content_sub-paragraph_title'
  } title`
  const id = _id.toString()
  const getHeaderByTag = (tag) => {
    switch (tag.toLowerCase()) {
      case 'h2':
        return (
          <h2
            className={titleClassName}
            {...register(id)}
          >
            {title}
          </h2>
        )
      case 'h3':
        return (
          <h3
            className={titleClassName}
            {...register(id, { parent })}
          >
            {title}
          </h3>
        )
      case 'h4':
        return (
          <h4
            className={titleClassName}
            {...register(id, { parent })}
          >
            {title}
          </h4>
        )
      case 'h5':
        return (
          <h5
            className={titleClassName}
            {...register(id, { parent })}
          >
            {title}
          </h5>
        )
      case 'h6':
        return (
          <h6
            className={titleClassName}
            {...register(id, { parent })}
          >
            {title}
          </h6>
        )
      default:
        return (
          <h2
            className={titleClassName}
            {...register(id)}
          >
            {title}
          </h2>
        )
    }
  }

  return (
    <div
      className={`${
        mainClassName + '_section_container_content_sub-paragraph'
      } ${isRecursion ? 'nested' : ''}`}
    >
      {getHeaderByTag(tag)}
      <Richtext
        className={`${
          mainClassName + '_section_container_content_sub-paragraph_text'
        } text`}
        textAlign={'left'}
        fullWidth={false}
        hasFlagTag={true}
      >
        {text}
      </Richtext>
      {_children ? (
        <div
          className={`${
            mainClassName + '_section_container_content_sub-paragraph_children'
          }`}
        >
          {_children.map((item, index) => {
            return (
              <SubParagraph
                key={index}
                tag={item.title_tipograpy}
                title={item.title}
                text={item.text}
                register={register}
                id={item.order}
                parent={id}
                _children={item.children}
                isRecursion
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default NavScroll
