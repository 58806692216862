import { AvatarIcon, AvatarWrapper } from 'design-react-kit'
import Image from 'next/image'

const AvatarWithText = ({ name, image, hasBottomBorder = false, ...props }) => {
  const avatarWrapperClassNames = `avatar_wrapper ${
    hasBottomBorder ? 'avatar_wrapper--border-bottom' : ''
  }`

  return (
    <div className={avatarWrapperClassNames}>
      <AvatarWrapper>
        <AvatarIcon size={'lg'}>
          <Image
            className={'avatar_wrapper_image'}
            src={image}
            alt={`Avatar ${name}`}
            fill
          />
        </AvatarIcon>
      </AvatarWrapper>
      <span className={'avatar_wrapper_text'}>{name}</span>
    </div>
  )
}

export default AvatarWithText
