import { Richtext } from '@/atoms'
import { formatMapUrl } from 'utils/functions'

const ContactsWithMap = ({ items }) => {
  const { title, text, google_maps_url } = items
  const mainClassName = 'contacts-map'

  const mapUrl = formatMapUrl(google_maps_url)
  const hasEmbeddedMap =
    google_maps_url !== undefined &&
    google_maps_url !== '' &&
    google_maps_url.length > 0

  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_wrapper'}`}>
        <div className={`${mainClassName + '_wrapper_text-wrapper'}`}>
          <h2 className={`${mainClassName + '_wrapper_text-wrapper_title'}`}>
            {title}
          </h2>
          <div
            className={`${mainClassName + '_wrapper_text-wrapper_richtext'}`}
          >
            <Richtext
              className={`${
                mainClassName + '_wrapper_text-wrapper_richtext_text'
              }`}
              textAlign={'left'}
              fullWidth={false}
            >
              {text}
            </Richtext>
          </div>
        </div>
        {hasEmbeddedMap ? (
          <div className={`${mainClassName + '_wrapper_map-wrapper'}`}>
            <iframe
              src={mapUrl}
              allowfullscreen
              width={'100%'}
              height={'100%'}
            />
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default ContactsWithMap
