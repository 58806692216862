import { fetchData } from 'api'
import { API_URLS, CONTENT_TYPES } from 'api/config'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { SearchGrid } from '..'

const mainClassName = 'generic-search'
const searchQuery = 'search'
const orderQuery = 'order'
const typeQuery = 'type'
const CARD_TYPE = 'LearnMoreCard'

const GenericSearch = (props) => {
  const {
    baseUrl,
    ctaCardsLabel,
    ctaSearch: ctaSearchLabel,
    description,
    title,
  } = props
  const [items, setItems] = useState([])
  const [isLoading, setIsLoaded] = useState(false)
  const queryParams = useSearchParams()
  const searchQueryValue = queryParams.get(searchQuery) ?? ''
  const orderQueryValue = queryParams.get(orderQuery) ?? ''
  const typeQueryValue = queryParams.get(typeQuery) ?? ''
  const typeQueryValueLabel = getCategoryById(typeQueryValue)
  const GENERIC_SEARCH_URL = API_URLS.BUILD_GENERIC_SEARCH_URL(
    searchQueryValue,
    typeQueryValueLabel,
    orderQueryValue,
    baseUrl,
  )

  const handleMultiCategoryCall = async (typesValues) => {
    const responseArray = await Promise.all(
      typesValues.map((typeValue) =>
        fetchDynamicData(
          API_URLS.BUILD_GENERIC_SEARCH_URL(
            searchQueryValue,
            typeValue,
            orderQueryValue,
            baseUrl,
          ),
        ),
      ),
    )
    const normalizedResponseArray = responseArray.flat().map((item, idx) => ({
      ...item,
      id: idx,
    }))
    return normalizedResponseArray
  }

  const fetchDynamicData = async (url) => {
    let genericSearchData = []
    let items = []
    genericSearchData = await fetchData(url)
    if (!genericSearchData) return []
    if (
      'object' === typeof genericSearchData &&
      Object.keys(genericSearchData).length !== 0
    ) {
      // transform object to array
      genericSearchData = Object.values(genericSearchData)
    }
    if (genericSearchData && !Array.isArray(genericSearchData))
      genericSearchData = [...Object.values(genericSearchData)]
    const adaptedGenericSearchData = adaptDataToCardComponent(
      genericSearchData,
      ctaCardsLabel,
    )
    items = [...adaptedGenericSearchData]
    return items
  }

  useEffect(() => {
    const getData = async () => {
      let items = []
      if (typeQueryValue.length > 1) {
        items = await handleMultiCategoryCall(typeQueryValueLabel)
        setItems(items)
        return
      }
      items = await fetchDynamicData(GENERIC_SEARCH_URL)
      setItems(items)
    }
    setIsLoaded(true)
    getData()
    setIsLoaded(false)
  }, [searchQueryValue, orderQueryValue, typeQueryValue])

  return (
    <section
      id={'search'}
      className={mainClassName + '_section'}
    >
      <SearchGrid
        title={title}
        description={description}
        ctaCardsLabel={ctaCardsLabel}
        ctaSearchLabel={ctaSearchLabel}
        cardType={CARD_TYPE}
        searchQueryValue={searchQueryValue}
        items={items}
        categories={categories}
        categoriesLabel={'Filtra per categoria'}
        pageSize={6}
        areFiltersEnabled={true}
        isCategoriesFilterEnabled={true}
        isTagFilterEnabled={false}
        areResoultsShownOnMount={false}
        isResultsContainerRow={false}
        isOrderFilterEnabled={false}
        isLoading={isLoading}
        categoryQueryParam={typeQuery}
      />
    </section>
  )
}

const categories = [
  {
    id: 1,
    name: 'News',
    type: CONTENT_TYPES.NEWS,
  },
  {
    id: 2,
    name: 'Esempi virtuosi',
    type: CONTENT_TYPES.EXTERNAL_PROJECTS,
  },
  {
    id: 3,
    name: 'Strumenti utili',
    type: CONTENT_TYPES.USEFUL_TOOLS,
  },
  {
    id: 4,
    name: 'Buone pratiche',
    type: CONTENT_TYPES.BEST_PRACTICES,
  },
  {
    id: 5,
    name: 'Sub-investimenti',
    type: CONTENT_TYPES.SUB_INVESTMENTS,
  },
  {
    id: 6,
    name: 'Progetti',
    type: CONTENT_TYPES.INTERNAL_PROJECTS,
  },
  {
    id: 7,
    name: 'Pagine',
    type: 'page',
  },
]

const getCategoryById = (idsToNormalize) => {
  let ids = idsToNormalize.split(',').map((id) => parseInt(id))
  if (ids.length === 1) ids = ids.at(0)
  if (ids.length > 1) {
    return ids.map(
      (id) => categories.find((category) => category.id === id)?.type ?? null,
    )
  }
  return categories.find((category) => category.id === ids)?.type ?? null
}

const getCategoryIdByType = (type) => {
  return categories.find((category) => category.type === type)?.id ?? null
}

const buildLink = (link, type) => {
  const linkSplit = link.split('/').filter((s) => s !== '')
  const slug = linkSplit.splice(2) // remove https and domain
  return `/${slug.join('/')}`
}

const adaptDataToCardComponent = (data, ctaCardsLabel) => {
  if (!data) return []
  return data.map((item, idx) => {
    const { title, link, type } = item
    const categoryId = getCategoryIdByType(type)

    return {
      id: idx,
      title: title,
      categoryId,
      items: {
        cta: {
          url: buildLink(
            link.replace('subinvestimenti', 'sub-investimenti'),
            type,
          ),
          label: ctaCardsLabel,
          open_link_in_new_tab: false,
        },
      },
    }
  })
}

export default GenericSearch
