import { Breadcrumb, BreadcrumbItem } from 'design-react-kit'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { capitalize, withNextLink } from 'utils/functions'

const home = {
  breadcrumb: 'home',
  label: 'Home',
  href: '/',
}

const previewParent = {
  breadcrumb: null,
}

const Breadcrumbs = ({ className: _className = null }) => {
  const router = useRouter()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const baseClassName = _className ?? 'breadcrumbs'
  const pathname = router.pathname
  const searchParams = useSearchParams()
  const isDraft = searchParams.get('isDraft')

  const isLast = (index) => breadcrumbs.length === index + 1

  useEffect(() => {
    const linkPath = router.asPath.split('/').filter((item) => item !== '')

    const pathArray = [
      home,
      ...linkPath.map((path, index) => {
        const cleanParams = path.split('?')[0].split('#')[0]
        const label = capitalize(cleanParams.replace(/-/g, ' '))
        const isPageId = !isNaN(cleanParams)
        if (label === '_preview') return previewParent
        if (isDraft && isPageId)
          return {
            breadcrumb: searchParams.get('title').toLowerCase(),
            label: capitalize(searchParams.get('title')),
            href: '#',
          }
        return {
          breadcrumb: path,
          label,
          href: `/${linkPath.slice(0, index + 1).join('/')}`,
        }
      }),
    ].filter((path) => !!path.breadcrumb)

    if (pathArray.length > 1) {
      setBreadcrumbs(pathArray)
    }
  }, [router])

  if (breadcrumbs.length === 0) return null

  return (
    <Breadcrumb className={`${baseClassName + '_wrapper'}`}>
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbItem
          key={index}
          active={isLast(index)}
          className={`${baseClassName + '_wrapper_item'}`}
        >
          {withNextLink(
            breadcrumb.label,
            breadcrumb.href,
            {
              fontWeight: !isLast(index) ? 'bold' : 'normal',
              textDecoration: isLast(index) ? 'none' : 'underline',
              color: isLast(index) ? '#636363' : '#334C66',
            },
            true,
          )}
          {!isLast(index) ? (
            <span
              className='separator'
              style={{
                color: '#636363',
              }}
            >
              /
            </span>
          ) : null}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

export default Breadcrumbs
