import {
  Dropdown as DropdownDesignReactKit,
  DropdownMenu,
  DropdownToggle,
  Icon,
  LinkList,
  LinkListItem,
} from 'design-react-kit'
import { useState } from 'react'
import { v4 as UUID } from 'uuid'

const VARIANTS = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  outline: 'btn-outline',
  'outline-dark': 'btn-outlinedark',
  link: 'btn-link',
  inverted: 'btn-inverted',
}

const Dropdown = ({
  variant = 'primary',
  linkListItems,
  label = 'Dropdown',
  icons = ['it-plus', 'it-minus'],
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasIcons = icons.length > 0 && icons.some((icon) => icon !== null)
  const hasSingleIcon =
    icons.length === 1 || icons.some((icon) => icon === null)

  const handleToggle = () => setIsOpen(!isOpen)

  const renderLinkListItems = () =>
    linkListItems?.map((item, index) => {
      const { name: label, onClick, isActive, id } = item
      return (
        <LinkListItem
          key={UUID()}
          onClick={onClick}
          active={isActive}
        >
          <span>{label.at(0).toUpperCase() + label.slice(1)}</span>
          {hasIcons ? renderCorrectIcon(icons, isActive, hasSingleIcon) : null}
        </LinkListItem>
      )
    })

  return (
    <DropdownDesignReactKit
      className={'mr-3'}
      isOpen={isOpen}
      toggle={handleToggle}
      direction={'down'}
    >
      <DropdownToggle
        caret
        outline={false}
        className={`${VARIANTS[variant]} custom-dropdown_toggle`}
      >
        {label}
        {/* <ChevronDown className={'custom-dropdown_toggle_chevron'} /> */}
      </DropdownToggle>
      <DropdownMenu style={{}}>
        <LinkList
          style={{
            maxHeight: '18.75rem',
            overflowY: 'auto',
          }}
        >
          {renderLinkListItems()}
        </LinkList>
      </DropdownMenu>
    </DropdownDesignReactKit>
  )
}

const renderCorrectIcon = (icons, isActive, hasSingleIcon) => {
  const [iconActive, iconInactive] = icons
  if (hasSingleIcon && !isActive) return null
  if (hasSingleIcon && isActive) {
    return (
      <Icon
        icon={iconActive}
        className={'custom-dropdown_icon'}
      />
    )
  }
  return (
    <Icon
      icon={!isActive ? iconActive : iconInactive}
      className={'custom-dropdown_icon'}
    />
  )
}

export default Dropdown
