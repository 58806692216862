const mainClassName = 'tag'

const VARIANT = {
  primary: 'primary',
}

const Tag = ({
  children = 'TAG LABEL',
  label = null,
  variant = 'primary',
  className,
}) => {
  const labelContent = label ? label : children
  return (
    <section className={`${mainClassName}_section`}>
      <span
        className={`${mainClassName}_section_tag ${VARIANT[variant]} ${className}`}
      >
        {labelContent.toLowerCase()}
      </span>
    </section>
  )
}

export default Tag
