import { BlockquoteCard, Richtext, Slider } from '@/atoms'

const mainClassName = 'blockquote'

const sliderBreakpoints = {
  0: {
    slidesPerView: 1.1,
    spaceBetween: 24,
  },
  415: {
    slidesPerView: 2,
  },
  576: {
    slidesPerView: 2,
  },
  850: {
    slidesPerView: 2,
    spaceBetween: 24,
  },
  1100: {
    slidesPerView: 3,
    spaceBetween: 24,
  },
}

const Blockquote = ({ items }) => {
  const { title, description, items: _items } = items
  const hasItems = _items && _items.length > 0 ? true : false

  const sliderItems =
    hasItems &&
    _items?.map((item, index) => {
      return (
        <BlockquoteCard
          key={`blockquote-card-${index}`}
          innerKey={`blockquote-card-${index}`}
          {...item}
        />
      )
    })
  return (
    <section className={`${mainClassName}_section`}>
      <div className={`${mainClassName}_section_container`}>
        <div className={`${mainClassName}_section_container_paragraph`}>
          <h2 className={`${mainClassName}_section_container_paragraph_title`}>
            {title}
          </h2>
          <Richtext
            textAlign={'center'}
            className={`${mainClassName}_section_container_paragraph_description`}
          >
            {description}
          </Richtext>
        </div>
        {hasItems ? (
          <div className={`${mainClassName}_section_container_slider`}>
            <Slider
              breakpoints={sliderBreakpoints}
              variant={'light'}
            >
              {sliderItems}
            </Slider>
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default Blockquote
