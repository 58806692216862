import { SUB_CATEGORY_BY_TYPE } from '@/molecules/Search/AreaSearch'
import { fetchData, getApiUrl } from 'api'
import { API_URLS, CONTENT_TYPES } from 'api/config'

const AreaSearch = async (data) => {
  if (!data) return {}
  const { type } = data
  const baseUrl = getApiUrl()
  const tagsUrl = API_URLS.GET_TAGS(baseUrl)
  const tags = await getTags(tagsUrl)
  let subCategories = []

  if (SUB_CATEGORY_BY_TYPE[type]) {
    const subCategoryUrl = getSubCategoryUrlByType(null, type, baseUrl)
    let subCategoryData = await fetchData(subCategoryUrl)
    if (!Array.isArray(subCategoryData))
      subCategoryData = [...Object.values(subCategoryData)]
    const adaptedSubCategoryData = adaptSubCategoryByType(subCategoryData, type)
    subCategories = adaptedSubCategoryData
  }

  const normalizedData = {
    ...data,
    baseUrl,
    type: data.type.toLowerCase(),
    tags,
    subCategories,
  }

  return normalizedData
}

const getTags = async (tagsUrl) => {
  const tagsResponse = await fetchData(tagsUrl)
  const tags = tagsResponse.map(({ id, name }) => ({
    id,
    name,
  }))
  return tags
}
export const getSubCategoryUrlByType = (searchQueryValue, type, baseUrl) => {
  switch (type.toLowerCase()) {
    case CONTENT_TYPES.NEWS:
      return API_URLS.GET_NEWS_CATEGORIES(baseUrl)
    case CONTENT_TYPES.USEFUL_TOOLS:
      return API_URLS.GET_USEFUL_TOOL_CATEGORIES(baseUrl)
    case CONTENT_TYPES.EXTERNAL_PROJECTS:
    case CONTENT_TYPES.SUB_INVESTMENTS:
    case CONTENT_TYPES.INTERNAL_PROJECTS:
    case CONTENT_TYPES.BEST_PRACTICES:
      return false
    default:
      return API_URLS.GET_TAGS(baseUrl, searchQueryValue, null, null)
  }
}

export const adaptSubCategoryByType = (data, type) => {
  switch (type.toLowerCase()) {
    case CONTENT_TYPES.NEWS:
    case CONTENT_TYPES.USEFUL_TOOLS:
      return data.map(({ id, name }) => ({ id, name }))
    default:
      return data
  }
}

export default AreaSearch
