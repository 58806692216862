import {
  Pager as PagerDesignReactKit,
  PagerItem as PagerItemDesignReactKit,
  PagerLink,
} from 'design-react-kit'
import { useMediaQuery, usePagination } from 'hooks'

const mockedPagination = {
  currentPage: 1,
  pageSize: 10,
  totalItems: 100,
  totalPages: 10,
}
const DOTS = 'DOTS'

const Pager = ({
  onPageChange = () => {},
  totalCount = 20,
  siblingCount = 1,
  currentPage = 20,
  pageSize = 1,
  className,
  ...props
}) => {
  const isDesktop = useMediaQuery('md')
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: isDesktop ? siblingCount : 0,
    pageSize,
  })
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === paginationRange.at(-1)

  const onNext = () => onPageChange(currentPage + 1)
  const onPrevius = () => onPageChange(currentPage - 1)

  return (
    <PagerDesignReactKit aria-label='Pager'>
      <>
        {isDesktop ? (
          <>
            <PagerItem
              label={'Precedente'}
              isPrevius
              onClick={onPrevius}
              isDisabled={isFirstPage}
            />

            {paginationRange.map((pageNumber, index) => {
              const isDots = pageNumber === DOTS
              const isCurrentPage = currentPage === pageNumber
              const handleClick = isDots
                ? {}
                : { onClick: () => onPageChange(pageNumber) }
              return (
                <PagerItem
                  key={index}
                  label={pageNumber}
                  isCurrentPage={isCurrentPage}
                  isDots={isDots}
                  {...handleClick}
                />
              )
            })}
            <PagerItem
              label={'Successiva'}
              isNext
              onClick={onNext}
              isDisabled={isLastPage}
            />
          </>
        ) : (
          <>
            <li
              className={
                'custom-pager_number-row-mobile d-flex w-100 justify-content-center'
              }
            >
              <ul>
                {paginationRange.map((pageNumber, index) => {
                  const isDots = pageNumber === DOTS
                  const isCurrentPage = currentPage === pageNumber
                  const handleClick = isDots
                    ? {}
                    : { onClick: () => onPageChange(pageNumber) }
                  return (
                    <PagerItem
                      key={index}
                      label={pageNumber}
                      isCurrentPage={isCurrentPage}
                      isDots={isDots}
                      {...handleClick}
                    />
                  )
                })}
              </ul>
            </li>

            <li
              className={
                'd-flex w-100 justify-content-center mt-3 custom-pager_ctas '
              }
            >
              <ul>
                <PagerItem
                  tag={'a'}
                  label={'Precedente'}
                  isPrevius
                  onClick={onPrevius}
                  isDisabled={isFirstPage}
                />
                <PagerItem
                  label={'Successiva'}
                  isNext
                  isDisabled={isLastPage}
                  onClick={onNext}
                />
              </ul>
            </li>
          </>
        )}
      </>
    </PagerDesignReactKit>
  )
}

const PagerItem = ({
  label,
  isPrevius = false,
  isNext = false,
  isCurrentPage = false,
  isDots = false,
  isFirst = true,
  isLast = true,
  isDisabled = false,
  onClick = () => {},
  ...props
}) => {
  const textClassName = isNext || isPrevius ? 'text' : ''
  const previusClassName = isPrevius ? '_previous' : ''
  const nextClassName = isNext ? '_next' : ''
  const currentPageClassName = isCurrentPage ? '_current' : ''
  const disabledClassName = isDisabled ? ' disabled' : ''
  const normalClassName = !(
    isCurrentPage ||
    isNext ||
    isPrevius ||
    isFirst ||
    isLast
  )
    ? 'd-none d-sm-block'
    : ''
  const currentPageProps = isCurrentPage ? { 'aria-current': 'page' } : {}
  const handleClick = isDisabled ? () => {} : onClick
  return isDots ? (
    <PagerItemDesignReactKit
      tag={'li'}
      onClick={handleClick}
    >
      <PagerLink
        className={'custom-pager_item_dots'}
        tag={'span'}
      >
        &#8230;
      </PagerLink>
    </PagerItemDesignReactKit>
  ) : (
    <PagerItemDesignReactKit
      tag={'li'}
      onClick={handleClick}
    >
      <PagerLink
        {...currentPageProps}
        previous={isPrevius}
        next={isNext}
        tag={'a'}
        // href={''}
        className={`${textClassName} ${normalClassName} ${
          'custom-pager_item' +
          currentPageClassName +
          previusClassName +
          nextClassName +
          disabledClassName
        }`}
      >
        {label}
      </PagerLink>
    </PagerItemDesignReactKit>
  )
}

export default Pager
