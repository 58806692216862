import { Header, HeaderBrand, HeaderContent } from 'design-react-kit'

const SlimHeader = ({
  owner = 'Ministero della Cultura',
  href = 'https://www.beniculturali.it/',
}) => {
  return (
    <Header
      className={'slim-header-custom'}
      theme=''
      type='slim'
    >
      <HeaderContent>
        <HeaderBrand
          href={href}
          target={'_blank'}
          responsive
          style={{
            cursor: 'pointer',
          }}
        >
          {owner}
        </HeaderBrand>
        ,
      </HeaderContent>
    </Header>
  )
}
export default SlimHeader
