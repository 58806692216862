import parse, { domToReact } from 'html-react-parser'
import Link from 'next/link'
import { useEffect, useState } from 'react'

const textAlignProps = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
}

const Richtext = ({
  unparsedHtml = null,
  children = null,
  className = '',
  textAlign = 'center',
  fullWidth = true,
  isDefault = true,
  hasFlagTag = false,
  textStyle = {},
}) => {
  const [mounted, setMounted] = useState(false)
  const dataToParse = unparsedHtml ? unparsedHtml : children
  const defaultClassName = 'richtext-default'
  const classesToInject = `
    ${isDefault ? defaultClassName : ''}
    ${textAlignProps[textAlign]}
    ${className}
    ${fullWidth ? 'w-100' : ''}
  `

  useEffect(() => setMounted(true), [])

  if (!mounted || !children) return <></>
  return (
    <span
      className={classesToInject}
      style={textStyle}
    >
      {hasAnchorLinkFlagTag(dataToParse) && hasFlagTag
        ? parseAndReplaceWithAnchorLink(dataToParse)
        : parse(dataToParse)}
    </span>
  )
}

// if a word has <span style={{ color: '#ff0000' }}>...</span>
// then we need to add an anchor link to it
const hasAnchorLinkFlagTag = (text) => {
  if (text.includes('<span style="color: #ff0000;">')) return true
  if (text.includes('<span style="color: #ff0000">')) return true
  return false
}

const parseAndReplaceWithAnchorLink = (text) => {
  const options = {
    replace: ({ attribs, children, ...domNode }) => {
      if (!attribs) return
      const { style } = attribs
      if (!style) return
      if (style === 'color: #ff0000;') {
        const getHrefFromChildren = (children) => {
          let result = ''
          if (!children) return result
          if (children.length === 1) {
            result = children.at(0).data
          }
          const concatHref = children.map((child) => {
            if (child.type === 'text') {
              return child.data
            }
            if (child.children) {
              return getHrefFromChildren(child.children)
            }
          })
          result = concatHref.join('')
          return result.toLowerCase().trim().replaceAll(' ', '-')
        }
        const href = `#${getHrefFromChildren(children)}`
        return <Link href={href}>{domToReact(children, options)}</Link>
      }
    },
  }

  return parse(text, options)
}

export default Richtext
