const mainClassName = 'date-span'
const DateSpan = ({ date = null, children = '01/01/1970' }) => {
  const dateToShow = date ? date : children
  return (
    <section className={`${mainClassName}_section`}>
      <span className={`${mainClassName}_section_date`}>{dateToShow}</span>
    </section>
  )
}

export default DateSpan
