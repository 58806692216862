import RootLayout from '@/organisms/Layout/RootLayout'
import GeneralProvider from 'contexts/General'
import ResponsiveProvider from 'contexts/Responsive'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useState } from 'react'
import 'typeface-lora'
import 'typeface-roboto-mono'
import 'typeface-titillium-web'
import '../scss/bootstrap-italia-custom.scss'

function MyApp({ Component, pageProps }) {
  const [isLoading, setIsLoading] = useState(true)
  const router = useRouter()
  const { header, footer, title } = pageProps
  const pageKey = pageProps?.title || router.asPath

  return (
    <GeneralProvider
      options={pageProps.options}
      page={pageProps.additionalData}
    >
      <ResponsiveProvider>
        <AnimatePresence
          initial={false}
          onExitComplete={() => window.scrollTo(0, 0)}
          mode={'wait'}
        >
          <RootLayout
            title={title}
            header={header}
            footer={footer}
          >
            <Component
              key={pageKey}
              {...pageProps}
            />
          </RootLayout>
        </AnimatePresence>
      </ResponsiveProvider>
    </GeneralProvider>
  )
}

export default MyApp
