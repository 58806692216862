import { fetchData } from 'api'
import {
  API_URLS,
  CONTENT_TYPES,
  PARENT_PAGE_BY_CONTENT_TYPE,
} from 'api/config'
import {
  adaptSubCategoryByType,
  getSubCategoryUrlByType,
} from 'models/AreaSearch'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { SearchGrid } from '..'

const mainClassName = 'area-search'
const searchQuery = 'search'
const orderQuery = 'order'

const AreaSearch = ({ ...props }) => {
  const {
    ctaLabel: ctaCardLabel,
    ctaSearch: ctaSearchLabel,
    title,
    description,
    type,
    baseUrl,
    subCategories: _subCategories,
    tags,
  } = props
  const [items, setItems] = useState([])
  const [subCategories, setSubCategories] = useState(_subCategories ?? [])
  const [isLoading, setIsLoading] = useState(false)
  const queryParams = useSearchParams()
  const searchQueryValue = queryParams.get(searchQuery) ?? ''
  const orderQueryValue = queryParams.get(orderQuery) ?? 'desc'

  const hasToTagSearch =
    (tags &&
      tags.length > 0 &&
      tags.filter(
        ({ name }) =>
          searchQueryValue.includes(name.toLowerCase()) ||
          name.toLowerCase().includes(searchQueryValue),
      ).length > 0) ??
    false
  const hasToCategorySearch =
    (subCategories &&
      subCategories.length > 0 &&
      subCategories.filter(
        ({ name }) =>
          searchQueryValue.includes(name.toLowerCase()) ||
          name.toLowerCase().includes(searchQueryValue),
      ).length > 0) ??
    false
  const tagsQuerySequence = tags.map(({ id }) => id).join(',')
  const AREA_URL = getAreaDataUrlByType(
    searchQueryValue,
    type,
    baseUrl,
    orderQueryValue,
  )
  const SUB_CATEGORY_URL = getSubCategoryUrlByType(
    searchQueryValue,
    type,
    baseUrl,
  )
  const AREA_URL_BY_TAG = getAreaDataUrlByType(
    null,
    type,
    baseUrl,
    orderQueryValue,
    tagsQuerySequence,
  )

  const fetchDynamicData = async () => {
    let areaData,
      subCategoryData,
      areaDataByTag = []
    let items = []

    setIsLoading(true)
    if (subCategories?.length === 0 && SUB_CATEGORY_BY_TYPE[type] !== false) {
      subCategoryData = await fetchData(SUB_CATEGORY_URL)
      if (subCategoryData && !Array.isArray(subCategoryData))
        subCategoryData = [...Object.values(subCategoryData)]
      const adaptedSubCategoryData = adaptSubCategoryByType(
        subCategoryData,
        type,
      )
      setSubCategories(adaptedSubCategoryData)
    }

    if (hasToCategorySearch || hasToTagSearch) {
      const dataByTag = getAreaDataUrlByType(
        searchQueryValue,
        type,
        null,
        tagsQuerySequence,
      )

      let _dataByTag = (await fetchData(AREA_URL_BY_TAG)) ?? []
      if (_dataByTag && !Array.isArray(_dataByTag))
        _dataByTag = [...Object.values(_dataByTag)]
      areaDataByTag = adaptDataToCardComponentPropsByType(
        _dataByTag,
        type,
        ctaCardLabel,
        subCategories,
        tags,
      )
      if (areaDataByTag.length > 0) {
        areaDataByTag = areaDataByTag.filter(({ tag, category }) => {
          return (
            tag?.toLowerCase().includes(searchQueryValue) ||
            category?.toLowerCase().includes(searchQueryValue) ||
            searchQueryValue?.includes(category?.toLowerCase()) ||
            searchQueryValue?.includes(tag?.toLowerCase())
          )
        })
      }
    }

    areaData = await fetchData(AREA_URL)
    if (areaData && !Array.isArray(areaData))
      areaData = [...Object.values(areaData)]
    const adaptedData = adaptDataToCardComponentPropsByType(
      areaData,
      type,
      ctaCardLabel,
      subCategories,
      tags,
    )

    items = adaptedData
    if (areaDataByTag.length > 0) {
      const newitems = areaDataByTag.filter((item) => {
        const { id } = item
        const isPresent = items.some((item) => item.id === id)
        if (!isPresent) return item
      })
      items = [...items, ...newitems]
    }
    return items
  }

  useEffect(() => {
    const getData = async () => {
      const items = await fetchDynamicData()
      setItems(items)
    }
    setIsLoading(true)
    getData()
    setIsLoading(false)
  }, [searchQueryValue, orderQueryValue])

  return (
    <section
      id={'search'}
      className={`${mainClassName + '_section'}`}
    >
      <SearchGrid
        title={title}
        description={description}
        ctaCardLabel={ctaCardLabel}
        ctaSearchLabel={ctaSearchLabel}
        cardType={CARD_BY_TYPE[type]}
        searchQueryValue={searchQueryValue}
        items={items}
        categories={subCategories}
        categoriesLabel={'Filtra per categoria'}
        tags={tags}
        tagsLabel={'Filtra per tag'}
        pageSize={9}
        areFiltersEnabled={true}
        isCategoriesFilterEnabled={SUB_CATEGORY_BY_TYPE[type] !== false}
        isTagFilterEnabled={true}
        areResoultsShownOnMount={true}
        isResultsContainerRow={FLEX_DIR_BY_TYPE[type]}
        isOrderFilterEnabled={true}
        isLoading={isLoading}
      />
    </section>
  )
}

const CARD_BY_TYPE = {
  [CONTENT_TYPES.NEWS]: 'DynamicCard',
  [CONTENT_TYPES.EXTERNAL_PROJECTS]: 'DynamicCard',
  [CONTENT_TYPES.USEFUL_TOOLS]: 'LearnMoreCard',
  [CONTENT_TYPES.BEST_PRACTICES]: 'DynamicCard',
  [CONTENT_TYPES.SUB_INVESTMENTS]: 'DynamicCard',
  [CONTENT_TYPES.INTERNAL_PROJECTS]: 'DynamicCard',
}
const FLEX_DIR_BY_TYPE = {
  [CONTENT_TYPES.NEWS]: true,
  [CONTENT_TYPES.EXTERNAL_PROJECTS]: true,
  [CONTENT_TYPES.USEFUL_TOOLS]: false,
  [CONTENT_TYPES.BEST_PRACTICES]: true,
  [CONTENT_TYPES.SUB_INVESTMENTS]: true,
  [CONTENT_TYPES.INTERNAL_PROJECTS]: true,
}

export const SUB_CATEGORY_BY_TYPE = {
  [CONTENT_TYPES.NEWS]: 'news_category',
  [CONTENT_TYPES.EXTERNAL_PROJECTS]: false,
  [CONTENT_TYPES.USEFUL_TOOLS]: 'useful_tools_category',
  [CONTENT_TYPES.BEST_PRACTICES]: false,
  [CONTENT_TYPES.SUB_INVESTMENTS]: false,
  [CONTENT_TYPES.INTERNAL_PROJECTS]: false,
}

const getAreaDataUrlByType = (
  searchQueryValue,
  type,
  baseUrl,
  orderQueryValue,
  tags = null,
) => {
  switch (type.toLowerCase()) {
    case CONTENT_TYPES.NEWS:
      return API_URLS.BUILD_GET_NEWS_URL(
        baseUrl,
        searchQueryValue,
        null,
        'start_date',
        tags,
        orderQueryValue,
      )
    case CONTENT_TYPES.EXTERNAL_PROJECTS:
      return API_URLS.BUILD_GET_EXTERNAL_PROJECTS_URL(
        baseUrl,
        searchQueryValue,
        'start_date',
        tags,
        orderQueryValue,
      )
    case CONTENT_TYPES.USEFUL_TOOLS:
      return API_URLS.BUILD_GET_USEFUL_TOOLS_URL(
        baseUrl,
        searchQueryValue,
        null,
        'start_date',
        tags,
        orderQueryValue,
      )

    case CONTENT_TYPES.BEST_PRACTICES:
      return API_URLS.BUILD_GET_BEST_PRACTICES_URL(
        baseUrl,
        searchQueryValue,
        'start_date',
        tags,
        orderQueryValue,
      )
    case CONTENT_TYPES.SUB_INVESTMENTS:
      return API_URLS.BUILD_GET_SUB_INVESTMENTS_URL(
        baseUrl,
        searchQueryValue,
        'start_date',
        tags,
        orderQueryValue,
      )
    case CONTENT_TYPES.INTERNAL_PROJECTS:
      return API_URLS.BUILD_GET_INTERNAL_PROJECTS_URL(
        baseUrl,
        searchQueryValue,
        'start_date',
        tags,
        orderQueryValue,
      )
    default:
      return {}
  }
}

const adaptDataToCardComponentPropsByType = (
  data,
  type,
  ctaCardLabel = 'Approfondisci',
  subCategories,
  tags,
) => {
  switch (type.toLowerCase()) {
    case CONTENT_TYPES.NEWS:
    case CONTENT_TYPES.EXTERNAL_PROJECTS:
    case CONTENT_TYPES.BEST_PRACTICES:
    case CONTENT_TYPES.SUB_INVESTMENTS:
    case CONTENT_TYPES.INTERNAL_PROJECTS:
      return data.map(
        mapDataToDynamicCardProps(type, ctaCardLabel, subCategories, tags),
      )
    case CONTENT_TYPES.USEFUL_TOOLS:
      return data.map(
        mapDataToLearnMoreCardProps(type, ctaCardLabel, subCategories, tags),
      )
    default:
      return {}
  }
}

const mapDataToDynamicCardProps =
  (type, ctaCardLabel, subCategories, allTags) => (item, index) => {
    const {
      id,
      title,
      acf,
      ['news_category']: newsCategoryId,
      ['useful_tools_category']: usefulToolsCategoryId,
      content,
      slug,
      tags = null,
    } = item
    let categoryId =
      newsCategoryId?.at(0) ?? usefulToolsCategoryId?.at(0) ?? null
    let categoryTagLabel,
      tagId,
      tagLabel = ''

    if (categoryId && subCategories.length > 0) {
      const category = subCategories.find(({ id }) => id === categoryId)
      categoryTagLabel = category?.name
    }

    if (tags.length > 0 && allTags && allTags.length > 0) {
      tagId = tags.at(0) ?? tags[0] ?? ''
      const { name: tagName } = allTags.find(({ id }) => id === tagId) ?? ''
      tagLabel = tagName
    }
    return {
      id,
      title: title?.rendered || '',
      description: content?.rendered || '',
      image:
        acf?.news_cover ||
        acf?.best_practices_cover ||
        acf?.external_projects_cover ||
        acf?.internal_projects_cover ||
        acf?.sub_investments_cover ||
        acf?.useful_tools_cover ||
        null,
      date: acf?.start_date || null,
      isMobileOnly: true,
      tagId,
      categoryId,
      category: categoryTagLabel || null,
      tag: tagLabel || null,
      cta: {
        url: `${PARENT_PAGE_BY_CONTENT_TYPE[type]}/${slug}`,
        label: ctaCardLabel,
      },
    }
  }

const mapDataToLearnMoreCardProps =
  (type, ctaCardLabel, subCategories, allTags) => (item, index) => {
    const {
      id,
      title,
      acf,
      ['news_category']: newsCategoryId,
      ['useful_tools_category']: usefulToolsCategoryId,
      content,
      slug,
      tags = null,
    } = item

    let categoryId =
      newsCategoryId?.at(0) ?? usefulToolsCategoryId?.at(0) ?? null
    let categoryTagLabel,
      tagId,
      tagLabel = ''

    if (categoryId && subCategories.length > 0) {
      const category = subCategories.find(({ id }) => id === categoryId)
      categoryTagLabel = category?.name
    }

    if (tags?.length > 0) {
      tagId = tags.at(0) ?? ''
      const tagFound = allTags.find(({ id }) => id === tagId)
      tagLabel = tagFound?.name
    }

    return {
      id,
      title: title?.rendered || '',
      text: content?.rendered || '',
      tagId,
      categoryId,
      tag: tagLabel || null,
      items: {
        cta: {
          // those are old links, we need to use the new ones for external resources
          // url: `${ENDPOINTS[type]}/${slug}`,
          // label: acf?.external_url?.title ?? ctaCardLabel,
          url: acf?.external_url?.url ?? '#',
          label: ctaCardLabel,
          open_link_in_new_tab:
            (acf?.external_url?.target === '_blank' ? true : false) ?? true,
        },
      },
    }
  }
const projectcard = (ctaCardLabel, subCategories) => (item, index) => {
  const {
    title,
    acf,
    ['news_category']: newsCategoryId,
    ['useful_tools_category']: usefulToolsCategoryId,
    content,
    slug,
    tags = null,
  } = item
  let categoryId = newsCategoryId?.at(0) ?? usefulToolsCategoryId?.at(0) ?? null
  let categoryTagLabel,
    tagId,
    tagLabel = ''

  if (categoryId && subCategories.length > 0) {
    const category = subCategories.find(({ id }) => id === categoryId)
    categoryTagLabel = category?.name
  }

  if (tags.length > 0) {
    tagId = tags.at(0) ?? ''
    const { name: tagName } = allTags.find(({ id }) => id === tagId)
    tagLabel = tagName
  }
  return {
    id: index,
    title: title?.rendered || '',
    description: content?.rendered || '',
    tagId,
    tag: tagName,
    cta: {
      url: `${CONTENT_TYPES.NEWS}/${slug}`,
      label: ctaCardLabel,
    },
  }
}

const cleanUrl = (url) => {
  const develop = 'https://develop.digital-library-2023-fe.n3.caffeina.host'
  return url.replace(develop, '')
}

export default AreaSearch
