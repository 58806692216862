import React, { createContext } from 'react'

export const GeneralContext = createContext({
  options: null,
  page: null
})

const GeneralProvider = ({
  options,
  page,
  children
}) => (
  <GeneralContext.Provider
    value={{
      options,
      page,
    }}
  >
    {children}
  </GeneralContext.Provider>
)

export default GeneralProvider
