import { Richtext, TabsComponent } from '@/atoms'

const mainClassName = 'tab-text'

const TabText = ({ items }) => {
  const { title, description, items: _items } = items

  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_paragraph'}`}>
          <h2
            className={`${
              mainClassName + '_section_container_paragraph_title'
            }`}
          >
            {title}
          </h2>
          <Richtext
            className={`${
              mainClassName + '_section_container_paragraph_description'
            }`}
            textAlign={'left'}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClassName + '_section_container_tabs'}`}>
          <TabsComponent items={_items} />
        </div>
      </div>
    </section>
  )
}

export default TabText
