import { Richtext } from '@/atoms'

const mainClass = 'content-paragraph-list'

const ContentParagraphList = ({ items, ...props }) => {
  const { title, description, items: contentListItems } = items
  return (
    <section className={`${mainClass}_section`}>
      <div className={`${mainClass}_section_container`}>
        <div className={`${mainClass}_section_container_paragraph`}>
          <h2 className={`${mainClass}_section_container_paragraph_title`}>
            {title}
          </h2>
          <Richtext
            className={`${mainClass}_section_container_paragraph_description`}
            textAlign={'left'}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClass}_section_container_list`}>
          {contentListItems.length > 0
            ? contentListItems.map((item, index) => (
                <ListItem
                  key={index}
                  item={item}
                />
              ))
            : null}
        </div>
      </div>
    </section>
  )
}

const ListItem = ({ item }) => {
  const { title, items } = item
  return (
    <div className={`${mainClass}_section_container_list_item`}>
      <h3 className={`${mainClass}_section_container_list_item_title`}>
        {title}
      </h3>
      <div className={`${mainClass}_section_container_list_item_content`}>
        {items.length > 0
          ? items.map((item, index) => (
              <TextItem
                key={index}
                item={item}
              />
            ))
          : null}
      </div>
    </div>
  )
}

const TextItem = ({ item }) => {
  const { title, text } = item
  return (
    <div className={`${mainClass}_section_container_list_item_content_item`}>
      <h4
        className={`${mainClass}_section_container_list_item_content_item_title`}
      >
        {title}
      </h4>
      <Richtext
        className={`${mainClass}_section_container_list_item_content_item_description`}
        textAlign={'left'}
      >
        {text}
      </Richtext>
    </div>
  )
}

export default ContentParagraphList
