import { Richtext } from '@/atoms'
import Image from 'next/image'

const FullImageParagraph = ({ items, ...props }) => {
  const {
    text: title,
    image,
    description,
    useParallax = false,
    objectFit: imageObjectFit = 'cover',
  } = items

  return (
    <section className={'full-image-paragraph_section'}>
      <div className={'full-image-paragraph_wrapper'}>
        <div className={'full-image-paragraph_wrapper_paragraph-row'}>
          <h2 className={'full-image-paragraph_wrapper_paragraph-row_title'}>
            {title}
          </h2>
          <Richtext textAlign={'center'}>{description}</Richtext>
        </div>
        <div className={'full-image-paragraph_wrapper_image-row'}>
          {useParallax ? (
            <div
              className={
                'full-image-paragraph_wrapper_image-row_image-wrapper parallax'
              }
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
          ) : (
            <Image
              src={image.url}
              alt={image.alt}
              fill
              style={{ objectFit: imageObjectFit }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default FullImageParagraph
