import { AvatarWithText, Button, Richtext } from '@/atoms'

const AvatarImageList = ({ items, ...props }) => {
  const { title, description, cta, items: _items } = items
  const hasItems = _items && _items.length > 0 ? true : false
  const hasCta = cta && cta.label ? true : false
  return (
    <section className={'avatar-img-list_section-wrapper flex-row'}>
      <div className={'avatar-img-list_section-wrapper_paragraph'}>
        <h2 className={'avatar-img-list_section-wrapper_paragraph_title'}>
          {title}
        </h2>
        <Richtext
          className={'avatar-img-list_section-wrapper_paragraph_description '}
          textAlign={'left'}
        >
          {description}
        </Richtext>
      </div>
      {hasItems ? (
        <div className={'avatar-img-list_section-wrapper_image-list'}>
          {hasItems
            ? _items.map((item, index) => (
                <AvatarListWithSubSectionTitle
                  items={item}
                  key={`avatar-img-list-${index}`}
                />
              ))
            : null}
        </div>
      ) : null}
      {hasCta ? (
        <div className={'avatar-img-list_section-wrapper_paragraph'}>
          <Button
            href={cta?.url}
            fullWidth={false}
            internalUrl={cta?.internal_url}
            isInternalPage={cta?.internal_page}
            openInNewTab={cta?.open_link_in_new_tab}
            className={'avatar-img-list_section-wrapper_paragraph_cta'}
          >
            {cta.label}
          </Button>
        </div>
      ) : null}
    </section>
  )
}

const AvatarListWithSubSectionTitle = ({ items, ...props }) => {
  const { title, description, items: _items } = items
  return (
    <div className={'avatar-img-list_section-wrapper_image-list_wrapper'}>
      <div>
        <h2
          className={
            'avatar-img-list_section-wrapper_image-list_wrapper_sub-section-title'
          }
        >
          {title}
        </h2>
        {description ? (
          <Richtext
            className={
              'avatar-img-list_section-wrapper_image-list_wrapper_sub-section-description'
            }
            textAlign={'left'}
          >
            {description}
          </Richtext>
        ) : null}
      </div>
      <div>
        {_items && _items.length > 0
          ? _items?.map((item, index) => (
              <AvatarWithText
                {...item}
                hasBottomBorder
                key={`avatar-img-list-${index}`}
              />
            ))
          : null}
      </div>
    </div>
  )
}

export default AvatarImageList
